import React, { PropsWithChildren } from "react"

import { Color } from "../../../constants/V2/color"
import Grid from "../../elements/V2/Grid"
import GridSectionTitles, {
  GridSectionTitleProps,
} from "../../elements/V2/GridSectionTitles"
import LayoutSection from "../LayoutComponents/LayoutSection"

import { getAccentColor } from "@utils/V2/color"

type Props = PropsWithChildren<
  GridSectionTitleProps & {
    id?: string
    headingSize?: "small" | "large"
    overrideSuperscriptColor?: Color
    overrideHeadingTextColor?: Color
    overrideHeadingBodyColor?: Color
    primaryBackgroundColor: Color
    secondaryBackgroundColor?: Color
    alignment?: "center" | "left"
    className?: string
  }
>

export default function GridSection({
  id,
  headingSize = "large",
  superscriptText,
  overrideSuperscriptColor,
  headingText,
  overrideHeadingTextColor,
  headingBodyText,
  overrideHeadingBodyColor,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  alignment,
  className,
  children,
  ...props
}: Props) {
  return (
    <LayoutSection
      id={id}
      primaryBackgroundColor={primaryBackgroundColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      {...props}
    >
      {(superscriptText || headingText || headingBodyText) && (
        <GridSectionTitles
          size={headingSize}
          alignment={alignment}
          superscriptText={superscriptText}
          superscriptColor={
            overrideSuperscriptColor || getAccentColor(primaryBackgroundColor)
          }
          headingText={headingText}
          headingTextColor={
            overrideHeadingTextColor || getAccentColor(primaryBackgroundColor)
          }
          headingBodyText={headingBodyText}
          headingBodyColor={
            overrideHeadingBodyColor || getAccentColor(primaryBackgroundColor)
          }
        />
      )}

      <Grid className={className}>{children}</Grid>
    </LayoutSection>
  )
}
