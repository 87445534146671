import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import CategorySlide from "../../../V2/Lab/CategorySlider/CategorySlide"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

export default function CategorySliderStoryblok({
  blok,
  ...props
}: Storyblok.BlokProps<Storyblok.CategorySlide> & {
  color: Color.Charcoal | Color.White
}) {
  return (
    <CategorySlide
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
      categoryHeadingText={blok.categoryHeadingText}
      headingText={blok.headingText}
      descriptionText={blok.descriptionText}
      buttonText={blok.ctaText}
      buttonUrl={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : ""
      }
      image={blok.image?.filename}
      imageAlt={blok.image?.alt}
      {...props}
    />
  )
}

CategorySliderStoryblok.displayName = "SwiperSlide"
