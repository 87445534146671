import classNames from "classnames"
import debounce from "lodash/debounce"
import React, { useRef, useCallback, useState, useEffect } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper } from "swiper/react"

import { Color } from "../../../../constants/V2/color"
import CircledIconButton from "../../../elements/V2/Buttons/CircledIconButton"
import Typography from "../../../elements/V2/Typography"
import GridSection from "../../Layouts/GridSection"

import { getAccentColor } from "@utils/V2/color"

SwiperCore.use([Navigation, Pagination])

export interface NavigationHeader {
  headingText: string
}

type Props = {
  sectionId: string
  superscriptText?: string
  navigationHeaders: NavigationHeader[]
  mode?: "light" | "dark"
  children: React.ReactNode
}

const CategorySlider = ({
  sectionId,
  superscriptText,
  navigationHeaders,
  mode = "light",
  children,
  ...props
}: Props) => {
  const navigationHeadersRef = useRef<HTMLDivElement>(null)
  const navigationHeadersXOffset = useRef(0)
  const swiperRef = useRef<SwiperCore | null>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const animatePaginationHeadings = useCallback(
    (swiper: SwiperCore) => {
      const direction = swiper.activeIndex > currentSlide ? "right" : "left"

      if (navigationHeadersRef.current) {
        if (direction === "right") {
          for (let index = currentSlide; index < swiper.activeIndex; index++) {
            navigationHeadersXOffset.current +=
              parseFloat(
                getComputedStyle(navigationHeadersRef.current).gap.replace(
                  "px",
                  ""
                )
              ) +
              navigationHeadersRef.current.children[
                index
              ].getBoundingClientRect().width
          }
        } else {
          for (
            let index = currentSlide - 1;
            index === swiper.activeIndex;
            index--
          ) {
            navigationHeadersXOffset.current -=
              parseFloat(
                getComputedStyle(navigationHeadersRef.current).gap.replace(
                  "px",
                  ""
                )
              ) +
              navigationHeadersRef.current.children[
                index
              ].getBoundingClientRect().width
          }
        }
        navigationHeadersRef.current.style.transform = `translate3d(-${navigationHeadersXOffset.current}px, 0px, 0px)`
      }

      setCurrentSlide(swiper.activeIndex)
    },
    [navigationHeadersRef, currentSlide]
  )

  const navigateToSlide = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index)
    }
  }

  const handleWindowResize = debounce(
    useCallback(() => {
      navigationHeadersXOffset.current = 0

      if (swiperRef.current && navigationHeadersRef.current) {
        for (let index = 0; index < currentSlide; index++) {
          navigationHeadersXOffset.current +=
            parseFloat(
              getComputedStyle(navigationHeadersRef.current).gap.replace(
                "px",
                ""
              )
            ) +
            navigationHeadersRef.current.children[index].getBoundingClientRect()
              .width
        }

        navigationHeadersRef.current.style.transform = `translate3d(-${navigationHeadersXOffset.current}px, 0px, 0px)`
      }
    }, [currentSlide]),
    500
  )

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [currentSlide, handleWindowResize])

  const backgroundColor = mode === "dark" ? Color.Black : Color.LightGray

  return (
    <div className="overflow-hidden" {...props}>
      <GridSection
        primaryBackgroundColor={backgroundColor}
        className="!gap-y-0"
      >
        {superscriptText && (
          <Typography
            size="subscript-lg"
            font="grotesk"
            weight="medium"
            color={getAccentColor(backgroundColor)}
            className="uppercase md-v2:pb-4-v2 lg-v2:pb-8-v2 opacity-40 whitespace-nowrap col-span-full"
            text={superscriptText}
          />
        )}
        <div
          className="flex gap-40-v2 md-v2:gap-80-v2 lg-v2:gap-104-v2 transition-all duration-300 items-end pb-40-v2 lg-v2:pb-48-v2"
          ref={navigationHeadersRef}
        >
          {navigationHeaders.map((header, index) => (
            <Typography
              key={`${sectionId}-category-slider-header-${index}`}
              size="display1"
              font="grotesk"
              weight="medium"
              color={
                currentSlide === index
                  ? Color.Blue
                  : getAccentColor(backgroundColor)
              }
              className={classNames(
                "whitespace-nowrap transition-all duration-300 hover:opacity-100 cursor-pointer",
                index < currentSlide
                  ? "opacity-0"
                  : index === currentSlide
                  ? "opacity-100"
                  : "opacity-15"
              )}
              onClick={() => navigateToSlide(index)}
              text={header.headingText}
              element="h2"
            />
          ))}
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          navigation={{
            nextEl: `.swiper-next-${sectionId}`,
            prevEl: `.swiper-prev-${sectionId}`,
          }}
          className="w-full max-w-full col-span-full"
          onSlideChange={animatePaginationHeadings}
        >
          {children}
          <div></div>
        </Swiper>
        <div className="flex items-end gap-12-v2 py-24-v2 md-v2:py-32-v2">
          <CircledIconButton
            color={getAccentColor(backgroundColor)}
            className={classNames(
              `swiper-prev-${sectionId}`,
              "z-10 transform rotate-180"
            )}
          />
          <CircledIconButton
            color={getAccentColor(backgroundColor)}
            className={classNames(`swiper-next-${sectionId}`, "z-10")}
          />
        </div>
      </GridSection>
    </div>
  )
}

export default CategorySlider
