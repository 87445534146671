import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import CategorySlide from "./CategorySlide"
import { Color } from "../../../../constants/V2/color"
import CategorySlider, {
  NavigationHeader,
} from "../../../V2/Lab/CategorySlider"

export default function CategorySliderStoryblok({
  blok,
}: Storyblok.BlokProps<Storyblok.CategorySlider>) {
  const navigationHeaders: NavigationHeader[] = blok.content.map((slide) => ({
    headingText: slide.categoryHeadingText,
  }))

  return (
    <CategorySlider
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
      sectionId={blok._uid}
      navigationHeaders={navigationHeaders}
      superscriptText={blok.superscriptText}
      mode={blok.mode}
    >
      {blok.content.map((content) => (
        <CategorySlide
          key={content._uid}
          blok={{
            ...content,
          }}
          color={blok.mode === "dark" ? Color.White : Color.Charcoal}
        />
      ))}
    </CategorySlider>
  )
}
